
import _ from "lodash";
import rest from "@/rest";
import { Component, Prop, Vue } from "vue-property-decorator";
import { Action, namespace } from "vuex-class";
import { vuex } from "@/store";
import router from "@/router";
import { PortalUserVM, SelectListItem, GroupVm } from "@/interfaces/PortalUserVM";
import { ExerciseVM } from "../interfaces/ExerciseVM";
import ExStatus from "../components/ExerciseComponents/ExStatus.vue";
import { ExBundleAssignmentVM } from "@/interfaces/ExBundleAssignmentVM";
import moment from "moment";
import { ParamDictionary } from "@/interfaces/ParamDictionary";

// import VueQrcode from "@chenfengyuan/vue-qrcode"; // https://github.com/fengyuanchen/vue-qrcode

const globals = namespace("globals");
const auth = namespace("auth");

@Component({
  components: {
    ExStatus
    // 'qrcode': VueQrcode
  }
})
export default class ExerciseBundleView extends Vue {
  @globals.Getter selectedExBundleAssignment!: ExBundleAssignmentVM;
  @globals.Getter selectedGroup!: GroupVm;
  // @globals.Getter selectedUser!: PortalUserVM;
  @auth.Getter isAdmin: any;
  @auth.Getter isGroupAdmin: any;
  // @auth.Getter isParent: any;
  // @auth.Getter isPupil: any;

  exercises: ExerciseVM[] = [];

  breadcrumbItems = [
    { text: "Start", disabled: false, href: "/home" },
    { text: "Schulungen", disabled: false, href: "/exercises" },
    { text: "Schulungspäckchen", disabled: true, href: "/exerciseBundle" }
  ];

  breadcrumbItemsFromCatalog = [
    { text: "Start", disabled: false, href: "/home" },
    { text: 'Schulungen', disabled: false, href: '/exercises' },
    // { text: "Schulungskatalog", disabled: false, href: "/exerciseCatalog" },
    { text: "Schulungspäckchen", disabled: true, href: "/exerciseBundle" }
  ];

  async mounted() {
    if (this.selectedExBundleAssignment == null) {
      router.push("/");
      return;
    }

    await this.$globalHelper.delay(50); // Wait until Scorm data is written to backend
    await this.updateExBundleAssignment();
    this.getExercises();
    // if (this.selectedExBundleAssignment.showResult)
    // console.log("ExerciseBundleView mounted");
    // await this.$globalHelper.delay(2000);
    vuex.quizplayerstore.saveCurrentRouteName();
  }

  async getExercises() {
    this.exercises = await rest
      .url("exercises/getExercises")
      .post(this.selectedExBundleAssignment);
  }

  async updateExBundleAssignment() {
    if (this.selectedExBundleAssignment.id == 0 || !this.selectedExBundleAssignment.userId)
      return;

    let exBundle = await rest
      .url("exercises/updateExBundleAssignment")
      .post(this.selectedExBundleAssignment);
      this.$store.commit('globals/setExBundleAssignment', exBundle);
  }

  startExercise(exercise: ExerciseVM) {
    if (!exercise.enabled)
      return;

    this.$store.commit("globals/setExercise", exercise);
    router.push("/exercise");
  }

  onLoadPdf() {
    let params: ParamDictionary = { dictionary: {} };
    params.dictionary!["UserId"] = this.selectedExBundleAssignment.userId!;
    params.dictionary!["BundleId"] = this.selectedExBundleAssignment.exBundle?.id.toString() ?? "0";
    params.dictionary!["GroupId"] = this.selectedExBundleAssignment.groupId.toString() ?? "0";

    this.$globalHelper.blobDownloadWithAuth("api/exercises/loadSingleCertificatePdf", `${this.selectedExBundleAssignment.user?.firstName} ${this.selectedExBundleAssignment.user?.lastName}-${this.selectedExBundleAssignment.exBundle?.name}-Zertifikat.pdf`, params);
  }

  finishedDateToLocal(finishedDate: string) {
    if (!finishedDate)
      return "";

    // let date = moment(finishedDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let date = moment(finishedDate).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return " | " + date;
  }

  dateToLocal(date: string) {
    if (!date)
      return "";

    // let date = moment(finishedDate).toDate().toLocaleString([], {year: 'numeric', month: 'numeric', day: 'numeric', hour: '2-digit', minute: '2-digit'});
    let dateFormated = moment(date).toDate().toLocaleString([], {year: 'numeric', month:  '2-digit', day: '2-digit'});
    return dateFormated;
  }

  goBack() {
    router.go(-1);
  }
}
